export class Response {
    public static staticResponse = {
        userGet: {
            "id": 1,
            "name": null,
            "email": null,
            "mobile": null,
            "location": null,
            "percentileScore": null,
            "goal": "IIT_JEE"
        },
        createSession: {
            "id": 4,
            "userId": 1,
            "subject": "MATHS",
            "topics": "ALGEBRA,TRIGONOMETRY",
            "subTopics": null,
            "goal": "IIT_NEET",
            "questions": [{
                "doubtId": 5733,
                "imageUrl": "https://s3-ap-southeast-1.amazonaws.com/howtosolveit/7/d0bea4df-ee21-418b-a3d1-b7d0cc59e092",
                "shareLink": "https://solveitnow.page.link/ypRRSNLBrwoz1bwE7",
                "subject": "MATHS",
                "topic": "ALGEBRA,TRIGONOMETRY",
                "subtopic": null,
                "timeToSolve": 2,
                "acePercentage": 5,
                "difficultyIndex": null,
                isMulti: true
            }, {
                "doubtId": 5736,
                "imageUrl": "https://s3-ap-southeast-1.amazonaws.com/howtosolveit/7/b0294007-738f-4309-93a7-141edf50e1f8",
                "shareLink": "https://solveitnow.page.link/DXsc4SojeuFzzddT8",
                "subject": "MATHS",
                "topic": "VECTORS_3D_GEOMETRY,TRIGONOMETRY",
                "subtopic": null,
                "timeToSolve": 1,
                "acePercentage": 20,
                "difficultyIndex": null,
                isMulti: false
            }, {
                "doubtId": 5727,
                "imageUrl": "https://s3-ap-southeast-1.amazonaws.com/howtosolveit/7/50837f24-7ae4-4c2b-baf3-18cfda57a17b",
                "shareLink": "https://solveitnow.page.link/JQMHbMCrzMt4WQxu5",
                "subject": "MATHS",
                "topic": "CALCULUS,TRIGONOMETRY",
                "subtopic": null,
                "timeToSolve": 1,
                "acePercentage": 20,
                "difficultyIndex": null,
                isMulti: true
            }
            ]
        },
        submitAnswer: true,
        updateStartTime: true,
        summary: {
            "sessionPercentile": 25,
            "dominantTopics": ["TRIGONOMETRY(Trigonometric Equations,Parabola)", "VECTORS_3D_GEOMETRY(Lines in Plane)"],
            "toImproveTopics": ["COORDINATE_GEOMETRY(Parabola)", "VECTORS_3D_GEOMETRY(Lines in Plane)"],
            "solutions": [{
                "doubtId": 5733,
                "questionImageUrl": "https://s3-ap-southeast-1.amazonaws.com/howtosolveit/7/d0bea4df-ee21-418b-a3d1-b7d0cc59e092",
                "answerImageUrls": [
                    "https://s3-ap-southeast-1.amazonaws.com/howtosolveit/7/d7c9d830-f653-4020-85bd-60f0bf485555",
                    "https://s3-ap-southeast-1.amazonaws.com/howtosolveit/7/3d398c39-d461-4241-9c43-8366e1fd2557"
                ],
                isCorrect: true,
                correctAnswer: 'A'
            }, {
                "doubtId": 5727,
                "questionImageUrl": "https://s3-ap-southeast-1.amazonaws.com/howtosolveit/7/50837f24-7ae4-4c2b-baf3-18cfda57a17b",
                "answerImageUrls": [
                    "https://s3-ap-southeast-1.amazonaws.com/howtosolveit/7/3d398c39-d461-4241-9c43-8366e1fd2557"
                ],
                isCorrect: true,
                correctAnswer: 'B'
            }, {
                "doubtId": 5736,
                "questionImageUrl": "https://s3-ap-southeast-1.amazonaws.com/howtosolveit/7/b0294007-738f-4309-93a7-141edf50e1f8",
                "answerImageUrls": [
                    "https://s3-ap-southeast-1.amazonaws.com/howtosolveit/7/8f2594ca-2bf5-49ee-8d40-10b58d9249b4",
                    "https://s3-ap-southeast-1.amazonaws.com/howtosolveit/7/d7c9d830-f653-4020-85bd-60f0bf485555"
                ],
                isCorrect: false,
                correctAnswer: 'C'
            }
            ],
            "userName": 'Temp'
        }

    }
}
