import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

// const $: any;
@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {

  isMenuActive = false;
  constructor(@Inject(DOCUMENT) document, private r: Renderer2, private route: Router) { }

  ngOnInit() {
  }

  menuToogle() {
    if (this.isMenuActive) {
      this.r.removeClass(document.body, 'w-navpane-is-open')
    } else {
      this.r.addClass(document.body, 'w-navpane-is-open');
    }
    this.isMenuActive = !this.isMenuActive;
  }

  navigateTo(page) {
    this.route.navigate(['/' + page]);
    this.menuToogle();
  }

}

