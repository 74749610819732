import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utility } from '../util/utility';
import { Constant } from '../util/constant';

@Injectable()
export class HttpService {

    private token;
    constructor(
        private httpClient: HttpClient,
    ) {
        this.token = null;
    }

    public setUserToken(token) {
        Utility.setLocalStorage(Constant.localStorageKey.userAuthToken, "Bearer " + token);
    }

    public getUserToken() {
        if (Utility.blank(this.token)) {
            this.token = Utility.getLocalStorage(Constant.localStorageKey.userAuthToken);
        }
        return this.token;
    }

    private getHeader(options?) {
        let reqHeaders = new HttpHeaders();
        if (Utility.nb(options) && Utility.nb(options.headers)) {
            options.headers.forEach(element => {
                reqHeaders = reqHeaders.append(element.key, element.value);
            });
        }
        reqHeaders = reqHeaders.append(Constant.httpHeaderKey.AuthToken, this.getUserToken());

        return reqHeaders;
    }

    public post<T>(data: any, url: string, customConfig?) {
        return this.executeHttp(data, url, 'post', customConfig);
    }
    public put<T>(data: any, url: string, customConfig?) {
        return this.executeHttp(data, url, 'put', customConfig);
    }
    public get<T>(url: string, customConfig?) {
        return this.executeHttp('', url, 'get', customConfig);
    }

    private executeHttp(data: any, url: string, methodType: string, customConfig?) {
        const config = this.buildConfig(data, url, methodType, customConfig);
        return this.http(config);
    }

    buildConfig(data: any, url: string, method, customConfig) {
        const config: any = {};

        url = Constant.restHost + url;

        config.url = url;
        config.method = method;
        config.data = data;
        config.observe = 'response';
        config.responseType = 'text';
        config.headers = this.getHeader(customConfig);

        return config;
    }
    private http<T>(config) {
        if (navigator && !navigator.onLine) {
            // this.us.notifySimple('Network Error - Make sure your device has an active Internet connection.');
            // return;
        }
        let observer;
        if (config.method === 'get' || config.method === 'delete') {
            observer = this.httpClient[config.method](config.url, { headers: config.headers });
        } else {
            if (config.data.acceptType) {
                observer = this.httpClient[config.method](config.url, config.data, { responseType: 'blob', headers: config.headers });
            } else {
                observer = this.httpClient[config.method](config.url, config.data, { observe: 'response', headers: config.headers });
            }
        }
        return observer;
    }

    /* private handleRespone<T>(observer, config) {
        return observer.subscribe(
            (result) => {
                if (config.data && config.data.acceptType === 'application/octet-stream') {
                    config.successCallback(result);
                } else if (config.method === 'get' && !result.body) {
                    config.successCallback(result);
                } else {
                    config.successCallback(result.body, result.headers);
                }
            },
            error => {
                this.handleError(error);
            });
    } */

    /**
     * Handle errors
     *
     */
    public handleError(error) {
        // this.loggingAspect.invokeOnThrowOfMethod(error);
        // this.us.stopLoad();
        console.log(error);
        if (error === 'logout') {
            // this.us.notifyError('Session Expired');
            // this.navigation.doLogoutPost();
            // tslint:disable-next-line:max-line-length
        } else if (error === 'Bad credentials' || (Utility.nb(error.error) && error.error instanceof String && error.error.indexOf('Bad Credential') > -1)) {
            // this.us.notifyError('Bad credentials');
            // this.navigation.doLogoutPost();
        } else if (error.status === 409) {
            // this.us.notifyError('Duplicate Data');
        } else if (error.status === 401 && Utility.nb(error.error)) {
            // this.us.notifyError('Session Expired');
            // this.navigation.doLogoutPost();
        } else if (error.status === 401) {
            // this.us.notifyError('You are not allowed to access this for now. Try re-login to refresh access.');
            // this.navigation.doLogoutPost();
        } else if (error.status === 500 && Utility.nb(error.error) && Utility.nb(error.error.exceptionCode)) {
            // this.us.notifyError(error.error.message, 5000);
            // console.log(error.error);
        } else {
            // this.us.notifyError('Technical Error Occurred');
        }
    }
}
