import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Constant } from '../util/constant';
import { HttpService } from './http.service';
import { Utility } from '../util/utility';
import { Response } from '../util/response';

@Injectable()
export class SharedService {

    constructor(private router: Router, private http: HttpService) {

    }

    verifyUser(token, callBack) {
        if (Utility.nb(token)) {
            Utility.clearLocalStorage();
            this.http.setUserToken(token);
            if (Constant.uiTestingOnly) {
                return callBack(Response.staticResponse.userGet);
            } else {
                this.http.get(Constant.restEndPoints.user.get).subscribe(
                    (res: any) => {
                        callBack(res);
                    }, (err) => {
                        Utility.clearLocalStorage();
                        this.http.handleError(err);
                    }
                );
            }
        }
    }

    createSession(body, callBack) {
        if (Utility.nb(body)) {
            if (Constant.uiTestingOnly) {
                return callBack(Response.staticResponse.createSession);
            } else {
                this.http.post(body, Constant.restEndPoints.session.createSession).subscribe(
                    (res: any) => {
                        callBack(res.body);
                    }, (err) => {
                        this.http.handleError(err);
                    }
                );
            }
        }
    }

    async updateStartTime(sessionId, queId, callBack) {
        if (Utility.nb(sessionId) && Utility.nb(queId)) {
            if (Constant.uiTestingOnly) {
                return callBack(Response.staticResponse.updateStartTime);
            } else {
                const url = Constant.restEndPoints.session.updateStartTime.replace('{:sessionId}', sessionId).replace('{:questionId}', queId);
                this.http.put('', url).subscribe(
                    (res: any) => {
                        callBack(res.body);
                    }, (err) => {
                        this.http.handleError(err);
                    }
                );
            }
        }
    }

    submitAnswer(sessionId, queId, answerOption, callBack) {
        if (Utility.nb(sessionId) && Utility.nb(queId) && Utility.nb(answerOption)) {
            if (Constant.uiTestingOnly) {
                return callBack(Response.staticResponse.submitAnswer);
            } else {
                const url = Constant.restEndPoints.session.saveAns.replace('{:sessionId}', sessionId).replace('{:questionId}', queId).replace('{:answerOption}', answerOption);
                this.http.put('', url).subscribe(
                    (res: any) => {
                        callBack(res.body);
                    }, (err) => {
                        this.http.handleError(err);
                    }
                );
            }
        }
    }

    fetchSummary(sessionId, callBack) {
        if (Utility.nb(sessionId)) {
            if (Constant.uiTestingOnly) {
                return callBack(Response.staticResponse.summary);
            } else {
                this.http.get(Constant.restEndPoints.session.summary.replace('{:sessionId}', sessionId)).subscribe(
                    (res: any) => {
                        callBack(res);
                    }, (err) => {
                        this.http.handleError(err);
                    }
                );
            }
        }
    }

    // Page navigation methods

    private navigateTo(url) {
        this.router.navigateByUrl(url);
    }

    navigateToHome() {
        this.navigateTo(Constant.frontEndPoints.home);
    }

    navigateToProblem() {
        // .replace('{:goal}', goalVal)
        this.navigateTo(Constant.frontEndPoints.session.problem);
    }

    navigateToResult(sessionId) {
        this.navigateTo(Constant.frontEndPoints.session.result.replace('{:sessionId}', sessionId));
    }
}