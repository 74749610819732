export class Constant {

    // public static restHost = 'http://localhost:8080/quiznow/';

    public static restHost = 'https://nanoquiz.in/quiznow/';


    public static uiTestingOnly = false;
	public static comingSoon = false;
    public static restEndPoints = {
        user: {
            get: 'solverUser/s'
        },
        session: {
            createSession: 'userTestSession/s/createSession',
            updateStartTime: 'userTestSessionQuestion/s/updateStartTime/{:sessionId}/{:questionId}',
            saveAns: 'userTestSessionQuestion/s/submitAnswer/{:sessionId}/{:questionId}/{:answerOption}',
            summary: 'userTestSession/s/summary/{:sessionId}'
        }
    }

    public static frontEndPoints = {
        home: '/',
        session: {
            problem: 'problem',
            result: 'result/{:sessionId}'
        }
    }

    public static localStorageKey = {
        userAuthToken: 'usr-auth-token',
        userGoal: 'usr-goal',
    }

    public static httpHeaderKey = {
        AuthToken: 'Authorization',
    }

    public static msg = {
        invalidUrl: 'Url is not authorised or invalid.',
        goalNotSelected: 'Please select your goal to continue.',
        subTopicNotSelected: 'Oops... please select goal, subject, and topic to continue.',
        maxTopicSelected: 'You can select maximum two topics.',
        answerNotSelected: 'Please select answer(s) to continue.',
    }

    public static staticData = {
        goal: [
            /* { id: 3, text: 'CAT/CMAT/GRE', value: 'CAT_GMAT_GRE' }, */
            { id: 0, text: 'IIT/JEE', value: 'IIT_JEE' },
            /* { id: 2, text: 'NEET', value: 'NEET' },*/
            { id: 1, text: 'SAT/AP', value: 'SAT_AP' }
        ],
        subject: [
            {
                id: 2,
                value: 'CHEMISTRY',
                text: 'Chemistry',
                topics: [
                    { id: 13, text: 'Inorganic', value: 'INORGANIC' },
                    { id: 14, text: 'Organic', value: 'ORGANIC' },
                    { id: 12, text: 'Physical', value: 'PHYSICAL' }
                ]
            },
            {
                id: 0,
                value: 'MATH',
                text: 'Math',
                topics: [
                    { id: 0, text: 'Algebra', value: 'ALGEBRA' },
                    { id: 2, text: 'Calculus', value: 'CALCULUS' },
                    { id: 3, text: 'Coordinate geometry', value: 'COORDINATE_GEOMETRY' },
                    { id: 5, text: 'Probability', value: 'PROBABILITY' },
                    { id: 1, text: 'Trigonometry', value: 'TRIGONOMETRY' },
                    { id: 4, text: '3D geometry', value: 'VECTORS_3D_GEOMETRY' },
                    { id: 16, text: 'Vectors', value: 'VECTORS' }
                ]
            },
            {
                id: 1,
                value: 'PHYSICS',
                text: 'Physics',
                topics: [
                    { id: 10, text: 'Electricity magnetism', value: 'ELECTRICITY_MAGNETISM' },
                    { id: 7, text: 'Heat', value: 'HEAT' },
                    { id: 6, text: 'Mechanics', value: 'MECHANICS' },
                    { id: 15, text: 'General physics ', value: 'GENERAL_PHYSICS' },
                    { id: 11, text: 'Modern physics ', value: 'MODERN_PHYSICS' },
                    { id: 9, text: 'Optics', value: 'OPTICS' },
                    { id: 8, text: 'Sound', value: 'SOUND' }
                ]
            }
        ],
        allTopics: {
            INORGANIC: { id: 13, text: 'Inorganic', value: 'INORGANIC' },
            ORGANIC: { id: 14, text: 'Organic', value: 'ORGANIC' },
            PHYSICAL: { id: 12, text: 'Physical', value: 'PHYSICAL' },
            ALGEBRA: { id: 0, text: 'Algebra', value: 'ALGEBRA' },
            CALCULUS: { id: 2, text: 'Calculus', value: 'CALCULUS' },
            COORDINATE_GEOMETRY: { id: 3, text: 'Coordinate geometry', value: 'COORDINATE_GEOMETRY' },
            PROBABILITY: { id: 5, text: 'Probability', value: 'PROBABILITY' },
            TRIGONOMETRY: { id: 1, text: 'Trigonometry', value: 'TRIGONOMETRY' },
            VECTORS_3D_GEOMETRY: { id: 4, text: '3D geometry', value: 'VECTORS_3D_GEOMETRY' },
            ELECTRICITY_MAGNETISM: { id: 10, text: 'Electricity magnetism', value: 'ELECTRICITY_MAGNETISM' },
            HEAT: { id: 7, text: 'Heat', value: 'HEAT' },
            MECHANICS: { id: 6, text: 'Mechanics', value: 'MECHANICS' },
            MORDERN_PHYSICS: { id: 11, text: 'Modern physics ', value: 'MORDERN_PHYSICS' },
            OPTICS: { id: 9, text: 'Optics', value: 'OPTICS' },
            SOUND: { id: 8, text: 'Sound', value: 'SOUND' },
            GENERAL_PHYSICS: { id: 15, text: 'General physics ', value: 'GENERAL_PHYSICS' },
            VECTORS: { id: 16, text: 'Vectors', value: 'VECTORS' }
        }
    }

}
