import { Constant } from './constant';
export class Utility {
    static nb(obj: any): boolean {
        if (obj == null) { return false; }
        if (obj === undefined) { return false; }
        // tslint:disable-next-line:quotemark
        if (obj instanceof String && (obj === '' || obj === "")) { return false; }
        if (obj instanceof Array && obj.length === 0) { return false; }
        // tslint:disable-next-line:quotemark
        if (obj === '' || obj === "") { return false; }
        return true;
    }
    static blank(obj: any): boolean {
        if (obj == null) { return true; }
        if (obj === undefined) { return true; }
        if (obj instanceof String && obj === '') { return true; }
        if (obj instanceof Array && obj.length === 0) { return true; }
        // tslint:disable-next-line:quotemark
        if (obj === '' || obj === "") { return true; }
        return false;
    }

    static setLocalStorage(key, value) {
        if (this.nb(key)) {
            localStorage.setItem(key, value);
        }
    }

    static getLocalStorage(key) {
        if (this.nb(key)) {
            return localStorage.getItem(key);
        }
    }

    static removeLocalStorage(key) {
        if (this.nb(key)) {
            localStorage.removeItem(key);
        }
    }

    static clearLocalStorage() {
        localStorage.clear();
    }

    static checkAuth() {
        const usrTookn = Utility.getLocalStorage(Constant.localStorageKey.userAuthToken);
        if (Utility.blank(usrTookn)) {
            alert(Constant.msg.invalidUrl);
            return false;
        }
        return true;
    }
}