import { Component, AfterViewInit, OnInit } from '@angular/core';
import { Constant } from '../util/constant';
import { Utility } from '../util/utility';
import { SharedService } from '../service/shared.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit, AfterViewInit {

  selGoal;
  usrTookn;
  utility = Utility;
  notAllowed;
  comingSoon = false;

  constructor(private route: ActivatedRoute, private shared: SharedService) {
	this.comingSoon = Constant.comingSoon;
  }

  ngOnInit() {
    this.notAllowed = false;
    this.route.params.subscribe(params => {
      if (Utility.nb(params.token)) {
        this.usrTookn = params.token;
      }
    });
  }

  ngAfterViewInit() {
    this.authUserAndDetails();
  }

  authUserAndDetails() {
    if (Utility.blank(this.usrTookn)) {
      this.usrTookn = Utility.getLocalStorage(Constant.localStorageKey.userAuthToken);
    }
    if (Utility.blank(this.usrTookn)) {
      alert(Constant.msg.invalidUrl);
      setTimeout(() => {
        this.notAllowed = true;
      }, 300);
      return;
    }
    const success = (res) => {
      console.log(res);
      this.selGoal = res.goal;
      if (Utility.nb(this.selGoal)) {
        Utility.setLocalStorage(Constant.localStorageKey.userGoal, this.selGoal);
      }
    }
    Utility.removeLocalStorage(Constant.localStorageKey.userGoal)
    this.shared.verifyUser(this.usrTookn, success);
  }

  toProblem() {
    if (Utility.nb(this.selGoal)) {
      Utility.setLocalStorage(Constant.localStorageKey.userGoal, this.selGoal);
    }
    this.shared.navigateToProblem();
  }
}
