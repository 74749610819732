import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../service/shared.service';
import { Utility } from '../util/utility';
import { Constant } from '../util/constant';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-problem',
  templateUrl: './problem.component.html',
  styleUrls: ['./problem.component.css']
})
export class ProblemComponent {

  msg;
  qMsg;
  blkTooltip;
  goals = Constant.staticData.goal;
  subjectSrc = Constant.staticData.subject;
  usrTookn;
  selGoal;
  canSeleGoal = true;
  isSubTop;

  selSub;
  fullScreenImageUrl;
  imgFullScreenEnabled = false;
  selTopic = [];
  selectedTopic = {};
  optTopic = [];
  sessionId;
  displayFinish = false;
  timeover = false;
  viewQueIndex = -1;
  currentQue = {
    doubtId: null,
    imageUrl: null,
    shareLink: null,
    timeToSolve: 0,
    acePercentage: 0,
    difficultyIndex: null,
    selAnswer: null,
    ansA: false,
    ansB: false,
    ansC: false,
    ansD: false,
    isMulti: false
  };
  allQue = [];
  isLoading = false;
  faSpinner = faSpinner;

  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;

  constructor(private route: ActivatedRoute, private shared: SharedService) {
    this.isSubTop = false;
    this.selSub = null;
    this.blkTooltip = false;
  }

  ngOnInit() {
    this.sessionId = null;
    if (!Utility.checkAuth()) {
      this.shared.navigateToHome();
    }
    const locGoal = Utility.getLocalStorage(Constant.localStorageKey.userGoal);
    if (Utility.nb(locGoal)) {
      this.selGoal = locGoal;
      this.canSeleGoal = false;
    }
  }

  ngAfterViewInit() {
    // this.authUserAndDetails();
  }

  chooseGoal(g): void {
    if (this.canSeleGoal) {
      if (Utility.nb(g) && Utility.nb(g.value)) {
        if (this.selGoal === g.value) {
          this.selGoal = null;
        } else {
          this.selGoal = g.value;
        }
      }
    }
  }

  chooseSubjct(s) {
    if (Utility.nb(s) && Utility.nb(s.value)) {
      if (this.selSub === null || this.selSub !== s.value) {
        this.selSub = s.value;
        this.optTopic = s.topics;
      } else {
        this.optTopic = [];
        this.selSub = null;
      }
      this.selTopic = [];
      this.selectedTopic = {};
    }
  }

  toggleFullscrn(imageUrl) {
    if (Utility.nb(imageUrl)) {
      this.imgFullScreenEnabled = true;
      this.fullScreenImageUrl = imageUrl;
      setTimeout(() => {
        this.scrollRight();
      }, 100);
    } else {
      this.imgFullScreenEnabled = false;
      this.fullScreenImageUrl = "";
    }
  }

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }

  chooseTpc(t) {
    if (Utility.nb(t) && Utility.nb(t.value)) {
      const txt = this.selTopic.filter(val => val === t.value);
      if (txt.length === 0) {
        if (this.selTopic.length < 2) {
          this.selTopic.push(t.value);
          this.selectedTopic[t.value] = true;
        } else {
          this.msg = Constant.msg.maxTopicSelected;
          setTimeout(() => {
            this.msg = null;
          }, 3000);
        }
      } else {
        const index = this.selTopic.indexOf(t.value, 0);
        if (index > -1) {
          this.selTopic.splice(index, 1);
          this.selectedTopic[t.value] = false;
        }
      }
    }
  }

  processToStartSession() {
    let topics = null;
    this.selTopic.forEach((value, i) => {
      if (i === 0) {
        topics = value;
      } else {
        topics = topics + "," + value;
      }
    });
    if (Utility.nb(this.selGoal) && Utility.nb(this.selSub) && Utility.nb(topics)) {
      this.createSession(topics);
    } else {
      this.msg = Constant.msg.subTopicNotSelected;
      setTimeout(() => {
        this.msg = null;
      }, 3000);
    }
  }

  selectAns(ans) {
    if (!this.currentQue.isMulti) {
      this.currentQue.ansA = false;
      this.currentQue.ansB = false;
      this.currentQue.ansC = false;
      this.currentQue.ansD = false;
    }

    switch (ans) {
      case 'A':
        this.currentQue.ansA = !this.currentQue.ansA;
        break;
      case 'B':
        this.currentQue.ansB = !this.currentQue.ansB;
        break;
      case 'C':
        this.currentQue.ansC = !this.currentQue.ansC;
        break;
      case 'D':
        this.currentQue.ansD = !this.currentQue.ansD;
        break;
      default:
        break;
    }
    // this.currentQue.selAnswer = ans;
  }

  createSession(tpics) {
    this.isLoading = true;
    this.allQue = [];
    this.sessionId = null;
    this.displayFinish = false;
    const success = (res) => {
      this.sessionId = res.id;
      if (Utility.nb(res.questions)) {
        this.allQue = res.questions;
        this.startQue();
      } else {
        this.isLoading = false;
      }
    }
    const body = {
      subject: this.selSub,
      topics: tpics,
      goal: this.selGoal
    }
    this.shared.createSession(body, success);
  }

  startQue() {
    this.viewQueIndex = 0;
    this.resetQue();
    setTimeout(() => {
      this.isSubTop = true;
      this.isLoading = false;
    }, 700);
  }

  resetQue() {
    this.currentQue = this.allQue[this.viewQueIndex];
    this.currentQue.selAnswer = null;
    this.currentQue.ansA = false;
    this.currentQue.ansB = false;
    this.currentQue.ansC = false;
    this.currentQue.ansD = false;

    this.timeover = false;
    if (this.viewQueIndex === (this.allQue.length - 1)) {
      this.displayFinish = true;
    }
    if (Utility.nb(this.currentQue.timeToSolve)) {
      setTimeout(() => {
        this.timeover = true;
      }, (1000 * 60 * this.currentQue.timeToSolve));
    }

    const success = (res) => {
    }
    this.shared.updateStartTime(this.sessionId, this.currentQue.doubtId, success);
  }

  submitAndNext() {
    this.isLoading = true;
    this.currentQue.selAnswer = '';
    if (this.currentQue.ansA) { this.currentQue.selAnswer = 'A'; }
    if (this.currentQue.ansB) { this.currentQue.selAnswer = this.currentQue.selAnswer + 'B'; }
    if (this.currentQue.ansC) { this.currentQue.selAnswer = this.currentQue.selAnswer + 'C'; }
    if (this.currentQue.ansD) { this.currentQue.selAnswer = this.currentQue.selAnswer + 'D'; }

    if (Utility.blank(this.currentQue.selAnswer)) {
      this.qMsg = Constant.msg.answerNotSelected;
      setTimeout(() => {
        this.qMsg = null;
        this.isLoading = false;
      }, 3000);
      return;
    }
    if (Utility.nb(this.sessionId) && Utility.nb(this.currentQue.doubtId)) {
      const success = (res) => {
        if (res) {
          if (this.viewQueIndex < (this.allQue.length - 1)) {
            this.viewQueIndex++;
            this.resetQue();
          } else if (this.displayFinish) {
            this.shared.navigateToResult(this.sessionId);
          }
          this.isLoading = false;
        }
      }
      this.shared.submitAnswer(this.sessionId, this.currentQue.doubtId, this.currentQue.selAnswer, success);
    }
  }


}
