import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../service/shared.service';
import { Utility } from '../util/utility';
import { Constant } from '../util/constant';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit, AfterViewInit {

  utility = Utility;
  allTopicsSrc = Constant.staticData.allTopics;
  sessionId;
  fullScreenImageUrl;
  imgFullScreenEnabled = false;
  smryRes = {
    sessionPercentile: 0,
    userName: '',
    solutions: [],
    dominantTopics: [],
    toImproveTopics: []
  };

  activeTab = 0;
  viewSol = { questionImageUrl: '', answerImageUrls: [], isCorrect: null, correctAnswer: null };
  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;
  constructor(private route: ActivatedRoute, private shared: SharedService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (Utility.nb(params.sessionId)) {
        this.sessionId = params.sessionId;
      }
    });
  }

  ngAfterViewInit(): void {
    if (Utility.blank(this.sessionId)) {
      this.shared.navigateToHome();
      return;
    }
    this.fetchSessionSummary();
  }

  toggleFullscrn(imageUrl) {
    if (Utility.nb(imageUrl)) {
      this.imgFullScreenEnabled = true;
      this.fullScreenImageUrl = imageUrl;
      setTimeout(() => {
        window.scroll(0, 30);
        this.scrollRight();
      }, 100);
    } else {
      this.imgFullScreenEnabled = false;
      this.fullScreenImageUrl = "";
    }
  }

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }

  fetchSessionSummary() {
    const success = (res) => {
      // console.log(res);
      this.smryRes = res;
      if (Utility.nb(this.smryRes)) {
        const tit = [];
        if (Utility.nb(this.smryRes.toImproveTopics)) {
          this.smryRes.toImproveTopics.forEach((v) => {
            if (v.indexOf("(") > -1) {
              const sep = v.split("(");
              v = this.allTopicsSrc[sep[0]].text + " (" + sep[1];
            } else {
              v = this.allTopicsSrc[v].text;
            }
            tit.push(v);
          });
        }
        const dt = [];
        if (Utility.nb(this.smryRes.dominantTopics)) {
          this.smryRes.dominantTopics.forEach((v) => {
            if (v.indexOf("(") > -1) {
              const sep = v.split("(");
              v = this.allTopicsSrc[sep[0]].text + " (" + sep[1];
            } else {
              v = this.allTopicsSrc[v].text;
            }
            dt.push(v);
          });
        }

        this.smryRes.dominantTopics = dt;
        this.smryRes.toImproveTopics = tit;
      }
      this.chooseTab(0);
    }
    this.shared.fetchSummary(this.sessionId, success);
  }

  chooseTab(t) {
    this.activeTab = t;
    if (t < this.smryRes.solutions.length) {
      this.viewSol = this.smryRes.solutions[t];
    }
  }


}
