import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";

import { AppComponent } from "./app.component";
import { TopBarComponent } from "./top-bar/top-bar.component";
import { HomePageComponent } from "./home-page/home-page.component";
import { ProblemComponent } from './problem/problem.component';
import { ResultComponent } from './result/result.component';
import { SharedService } from './service/shared.service';
import { HttpService } from './service/http.service';
import { HttpClientModule } from '@angular/common/http';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    PinchZoomModule,
    RouterModule.forRoot([
      { path: "", component: HomePageComponent },
      { path: "home/:token", component: HomePageComponent },
      { path: "problem", component: ProblemComponent },
      { path: "result/:sessionId", component: ResultComponent },
    ]),
    FontAwesomeModule,
  ],
  declarations: [AppComponent, TopBarComponent, HomePageComponent, ProblemComponent, ResultComponent],
  providers: [SharedService, HttpService],
  bootstrap: [AppComponent],
})
export class AppModule { }
